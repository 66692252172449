<template>
  <LayoutWithoutPadding>
    <div v-if="inviteRequired" class="flex flex-col items-center justify-center">
      <div class="w-full my-s32">
        <template v-if="!isSmallMobileDevice">
          <ImageDisconnectCablesDesktopDark v-if="currentTheme === 'dark'"/>
          <ImageDisconnectCablesDesktopLight v-else/>
        </template>
        <template v-else>
          <ImageDisconnectCablesDark v-if="currentTheme === 'dark'"/>
          <ImageDisconnectCablesLight v-else/>
        </template>
      </div>
      <div class="subheadline-large">
        {{ $t('kyc.country_not_available') }}
      </div>
      <div class="body-text-large mt-s16 px-s20" :style="{ 'max-width': '500px' }">
        {{ $t('kyc.we_re_sorry_country_unavailable') }}
      </div>
    </div>

    <div v-else-if="!isAuthenticated() || $route.params.invite" class="flex justify-center">
      <!-- Otherwise, show normal signup form -->
      <SignupForm
        v-if="!isAuthenticated()"
        @onSignup="continueAction"
        @onAppleAuth="continueAction('apple')"
        @onGoogleAuth="continueAction('google')"
        :is-loading="isLoading"
        :referral-code="referralCode"
        show-sso-apple
        show-sso-google
      />
    </div>

    <HCaptcha
      ref="hCaptchaRef"
      @onChallengeExpired="onClose"
      @onClosed="onClose"
      @onError="onError"
      @onExpired="onClose"
      @onVerify="onVerify"
    />
  </LayoutWithoutPadding>
</template>

<script>
  import { mapState } from 'vuex';

import { LayoutWithoutPadding } from '@/components/misc';
import USER_REGISTER from '@/graphql/mutations/UserRegister.gql';
import LANDING_PAGE_SIGNUP from '@/graphql/mutations/LandingPageSignUp.gql';
import HCaptcha from '@/components/misc/HCaptcha';
import {
  ImageDisconnectCablesDark,
  ImageDisconnectCablesLight,
  ImageDisconnectCablesDesktopDark,
  ImageDisconnectCablesDesktopLight,
} from '@/assets/img';
import SignupForm from '@/components/authentication/SignupForm/SignupForm';

import { isApiError } from '@/utils/error-handler';

export default {
  name: 'SignUp',
  components: {
    HCaptcha,
    ImageDisconnectCablesDark,
    ImageDisconnectCablesLight,
    ImageDisconnectCablesDesktopDark,
    ImageDisconnectCablesDesktopLight,
    LayoutWithoutPadding,
    SignupForm,
  },

  computed: {
    ...mapState('api', [
      'referralCode'
    ]),
  },

  data: function () {
    return {
      captcha: '',
      invite: this.$route.params.invite || '',
      inviteRequired: false,
      isLoading: false,
      signupFormData: null,
      ssoOrigin: null,
    };
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'SIGN_UP_VIEWED',
    });
  },

  methods: {
    async continueAction(event) {
      if (event) {
        if (event.type === 'form') {
          this.signupFormData = event;
        } else {
          this.ssoOrigin = event;
        }
      }
      this.inviteRequired && !this.invite ? await this.joinWaitlist() : this.register();
    },

    async handleRegisterError(err, authData) {
      this.$store.commit('ui/setState', { stateName: 'initialLoading', stateData: false });

      if (isApiError(err)) {
        if (err['error_code'] === 'invalid_captcha') {
          return this.onSubmit();
        }
        this.isLoading = false;
        if (err['error_code'] === 'invalid_2fa_code') {
          return this.showModal('TwoFactorAuthenticationVerification', { authData });
        } else if (err['error_code'] === 'country_not_allowed') {
          return this.showModal('CountryNotAllowed', { email: authData.email });
        } else if (err['error_code'] === 'web_requires_user_flag') {
          return this.showModal('WebRequiresUserFlag');
        } else if (err['error_code'] === 'invite_not_found') {
          this.invite = '';
        } else if (err['error_code'] === 'invite_required') {
          this.inviteRequired = true;
          return this.$store.dispatch('events/track', {
            event: 'SIGN_UP_INVITE_CODE_VIEWED',
          });
        } else if (err['error_code'] === 'must_agree_to_tos') {
          return this.showModal('UserAgreement', { authData, invite: this.invite, customClose: this.tosDisagree });
        } else {
          this.inviteRequired = false;
        }
      }

      this.isLoading = false;

      // This is to prevent showing an error to the user when he signs up
      if (err.message !== 'please_verify_your_email') {
        await this.showError(err);
      }
    },

    async joinWaitlist() {
      this.isLoading = true;
      const { email } = this.signupFormData;
      const { captcha } = this.$data;
      try {
        await this.$apollo.mutate({
          mutation: LANDING_PAGE_SIGNUP,
          variables: {
            email,
            captcha,
            ref: this.$route.query.ref,
          }
        });
        await this.showSuccess(this.$t('success.joined_waitlist'));
      } catch (err) {
        if (isApiError(err)) {
          if (err['error_code'] === 'invalid_captcha') {
            return this.onSubmit();
          }
        }
        this.isLoading = false;
        this.inviteRequired = false;
        await this.showError(err);
      }
    },

    onClose() {
      this.isLoading = false;
    },

    onError() {
      this.isLoading = false;
    },

    onSubmit() {
      this.isLoading = true;
      this.$refs.hCaptchaRef.execute();
    },

    async onVerify(captcha) {
      this.captcha = captcha;
      await this.continueAction();
    },

    register() {
      if (this.signupFormData) {
        this.registerWithPassword();
      } else {
        this.registerWithSso(this.ssoOrigin);
      }
    },

    async registerWithSso(origin) {
      const authToken = await this.ssoGetAuthToken(origin);
      if (!authToken) {
        return;
      }

      const { invite } = this.$data;

      this.isLoading = true;
      try {
        const authResult = await this.ssoAuth(authToken, origin);
        await this.handleRegister(authResult, invite);
      } catch (err) {
        await this.handleRegisterError(err, {
          email: null,
          ssoAuthToken: authToken,
          ssoOrigin: origin
        });
      } finally {
        this.isLoading = false;
      }
    },

    async registerWithPassword() {
      this.isLoading = true;
      const { email, password, ref } = this.signupFormData;
      const { captcha, invite } = this.$data;

      if (password === ref) {
        return this.showError(new Error('ref_code_equals_password'));
      }

      try {
        const response = await this.apolloApiCall({
          mutation: USER_REGISTER,
          variables: {
            email,
            password,
            captcha,
            ref,
            invite,
          }
        });
        const data = response.data['_signup'];
        await this.handleRegister(data, invite);
      } catch (err) {
        await this.handleRegisterError(err, email);
      }
    },
  },
};
</script>

<style scoped>

</style>
